<template>
  <!-- <v-row justify="center"> -->
  <v-dialog v-model="dialog" hide-overlay max-width="600px">
    <template v-slot:activator="{ on: dialoger, attrs }">
      <v-tooltip top :disabled="!(disableAddBankButton)">
        <template v-slot:activator="{ on: tooltip }">
          <div v-on="{...tooltip,...dialoger}">
            <v-btn :disabled="disableAddBankButton" id="add-account" v-bind="attrs">
            Add US Bank account
            </v-btn>
          </div>
        </template>
        <span>A maximum of 2 bank accounts are permitted.</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">User Profile</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" label-width="250px">
          <v-container>
            <v-row>
              <v-col cols="12" >
                <v-select
                  label="Ownership Type"
                  v-model="bankLogin.ownershipType"
                  placeholder="Type"
                  :items="['personal', 'business']"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" >
                <v-select
                  label="Account Type"
                  v-model="bankLogin.accountType"
                  placeholder="Account Type"
                  :items="['savings', 'checking']"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" >
                <v-text-field
                  label="Account Number"
                  placeholder="Account Number"
                  v-model="bankLogin.accountNumber"
                  :rules="requiredRule"
                  size="small"
                  clearable
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" >
                <v-text-field
                  label="Routing Number"
                  placeholder="Routing Number"
                  v-model="bankLogin.routingNumber"
                  size="small"
                  :rules="requiredRule"
                  clearable
                />
              </v-col>
            </v-row>
            <v-row v-if="bankLogin.ownershipType === 'business'">
              <v-col cols="12" >
                <v-text-field
                  label="Business Name"
                  placeholder="Business Name"
                  v-model="bankLogin.businessName"
                  size="small"
                  clearable
                />
              </v-col>
            </v-row>
            <v-row v-if="bankLogin.ownershipType === 'personal'">
              <v-col cols="12" >
                <v-text-field
                  label="First Name"
                  v-model="bankLogin.firstName"
                  size="small"
                  clearable
                />
              </v-col>
            </v-row>
            <v-row v-if="bankLogin.ownershipType === 'personal'">
              <v-col cols="12" >
                <v-text-field
                  label="Last Name"
                  v-model="bankLogin.lastName"
                  size="small"
                  clearable
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" >
                <v-textarea
                  label="Billing Address"
                  v-model="bankLogin.billingAddress.streetAddress"
                  type="textarea"
                  clearable
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Extended Address"
                  v-model="bankLogin.billingAddress.extendedAddress"
                  type="textarea"
                  clearable
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" >
                <v-text-field
                  label="City"
                  v-model="bankLogin.billingAddress.locality"
                  size="small"
                  :rules="requiredRule"
                  clearable
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" >
                <v-select
                  size="small"
                  v-model="bankLogin.billingAddress.region"
                  label="State"
                  :items="states"
                  :rules="requiredRule"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" >
                <v-text-field
                  label="Postal Code"
                  v-model="bankLogin.billingAddress.postalCode"
                  size="small"
                  :rules="zipRules"
                  clearable
                />
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col cols="12">
                <h3>Verification Method</h3>
                <v-radio-group v-model="verificationMethod">
                  <v-radio
                    label="Network Check"
                    value="network_check"
                  />
                  <v-radio
                    label="Micro Transfer"
                    value="micro_transfers"
                  />
                </v-radio-group>
              </v-col>
            </v-row> -->
            <v-row>
              <v-col cols="12" >
                <v-btn type="primary" @click="startBankLogin">
                  Add US Bank Account
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
  <!-- </v-row> -->
</template>

<script>
import axios from "axios";
import { PG_API_BASE } from "../env";

export default {
  props: {
    customerId: { type: String, required: true },
    customer: { type: Object, default: () => {} },
    braintreeCustomer: { type: Object, default: () => {} },
  },
  data() {
    return {
      BankComponent: null,
      verificationMethod: 'network_check',
      mandateText:
        'By clicking ["Add Bank"], I authorize Braintree, a service of PayPal, on behalf of Readyspaces (i) to verify my bank account information using bank information and consumer reports and (ii) to debit my bank account.',
      dialog: false,
      valid: false,
      states: [
        { text: "Alabama", value: "AL" },
        { text: "Alaska", value: "AK" },
        { text: "Arizona", value: "AZ" },
        { text: "Arkansas", value: "AR" },
        { text: "California", value: "CA" },
        { text: "Colorado", value: "CO" },
        { text: "Connecticut", value: "CT" },
        { text: "Delaware", value: "DE" },
        { text: "District of Columbia", value: "DC" },
        { text: "Florida", value: "FL" },
        { text: "Georgia", value: "GA" },
        { text: "Hawaii", value: "HI" },
        { text: "Idaho", value: "ID" },
        { text: "Illinois", value: "IL" },
        { text: "Indiana", value: "IN" },
        { text: "Iowa", value: "IA" },
        { text: "Kansas", value: "KS" },
        { text: "Kentucky", value: "KY" },
        { text: "Louisiana", value: "LA" },
        { text: "Maine", value: "ME" },
        { text: "Maryland", value: "MD" },
        { text: "Massachusetts", value: "MA" },
        { text: "Michigan", value: "MI" },
        { text: "Minnesota", value: "MN" },
        { text: "Mississippi", value: "MS" },
        { text: "Missouri", value: "MO" },
        { text: "Montana", value: "MT" },
        { text: "Nebraska", value: "NE" },
        { text: "Nevada", value: "NV" },
        { text: "New Hampshire", value: "NH" },
        { text: "New Jersey", value: "NJ" },
        { text: "New Mexico", value: "NM" },
        { text: "New York", value: "NY" },
        { text: "North Carolina", value: "NC" },
        { text: "North Dakota", value: "ND" },
        { text: "Ohio", value: "OH" },
        { text: "Oklahoma", value: "OK" },
        { text: "Oregon", value: "OR" },
        { text: "Pennsylvania", value: "PA" },
        { text: "Rhode Island", value: "RI" },
        { text: "South Carolina", value: "SC" },
        { text: "South Dakota", value: "SD" },
        { text: "Tennessee", value: "TN" },
        { text: "Texas", value: "TX" },
        { text: "Utah", value: "UT" },
        { text: "Vermont", value: "VT" },
        { text: "Virginia", value: "VA" },
        { text: "Washington", value: "WA" },
        { text: "West Virginia", value: "WV" },
        { text: "Wisconsin", value: "WI" },
        { text: "Wyoming", value: "WY" },
      ],
      bankLogin: {
        // displayName: 'Readyspaces',
        routingNumber: null,
        firstName: this.customer.firstName,
        lastName: this.customer.lastName,
        businessName: this.customer.company,
        ownershipType: "business",
        accountNumber: null,
        accountType: "savings",
        billingAddress: {
          streetAddress: this.customer.country === 'us' ? this.customer.address : '',
          extendedAddress: this.customer.country === 'us' ? this.customer.address2 : '',
          countryCodeAlpha2: this.customer.country === 'us' ? String(this.customer.country).toUpperCase() : '',
          locality: this.customer.country === 'us' ? this.customer.city : '',
          region: this.customer.country === 'us' ? this.customer.state : "",
          postalCode: this.customer.country === 'us' ? this.customer.zip : null,
        },
      },
      requiredRule: [(v) => !!v || "Field is required"],
      regionRules: [
        (v) => !!v || "Region is required",
        (value) => (value || "").length === 2 || "Not a valid Region code.",
      ],
      zipRules: [
        (v) => !!v || "Postal code is required",
        // v => /(^\d{9})|(^\d{5}-\d{4}$)|(^\d{5})|(^\d{5} \d{4}$)/.test(v) || 'Postal code must be valid',
        (v) =>
          /^\d{5}(-\d{4})?$/.test(v) ||
          "Postal code must be valid (12312) || (12312-1232)",
      ],
    };
  },
  computed: {
    disableAddBankButton(){
        return this.braintreeCustomer.usBankAccounts && this.braintreeCustomer.usBankAccounts.length > 1;
    }
  },
  async mounted() {
    var client = require("braintree-web/client");
    var usBankAccount = require("braintree-web/us-bank-account");
    const response = await axios.get(`${PG_API_BASE}/client/token`, {
      headers: {
        Authorization: `Bearer ${this.$keycloak.token}`,
      },
    });
    const clientInstance = await client.create({
      authorization: response.data.token.clientToken,
    });

    const BankComponent = await usBankAccount.create({
      client: clientInstance,
    });
    this.BankComponent = BankComponent;
  },
  methods: {
    async startBankLogin(ev) {
      ev.preventDefault();
      const valid = this.$refs.form.validate();
      if (!valid) {
        return;
      }
      try {
        const body = { ...this.bankLogin };

        if (body.ownershipType === "business") {
          delete body.firstName;
          delete body.lastName;
        } else {
          delete body.businessName;
        }
        const response = await this.BankComponent.tokenize({
          bankDetails: body,
          mandateText: this.mandateText,
        });

        //  console.log(response);
        const paymentMethodResponse = await axios.post(
          `${PG_API_BASE}/customer/create/payment`,
          {
            customerId: this.customerId,
            paymentMethodNonce: response.nonce,
            options: {
              usBankAccountVerificationMethod: this.verificationMethod,
              failOnDuplicatePaymentMethod: true,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${this.$keycloak.token}`,
            },
          }
        );

        if ( paymentMethodResponse.data.result.paymentMethod.verifications[0].status === 'failed' ) {
          this.$emit('error', 'Verification is failed! Please contact general manager for assistance.' );
        }

        this.dialog = false;
        this.$emit("bank-added");
      } catch (err) {
        // console.log(err);
        this.dialog = false;
        this.$emit(
          "error",
          `${err.details.originalError.details.originalError[0].message}`
        );
      }
    },
  },
};
</script>

<style scoped>
#add-account:enabled {
  background: #1E88E5 !important;
}

#add-account:disabled {
background: #1E88E5 !important;
cursor: not-allowed;
opacity: 0.8;
}
</style>
