<template>
    <v-container>
      <v-card>
        <v-form
          ref="form"
          label-width="250px"
        >
          <v-container>
            <v-card-title>
              <v-spacer />      
              Credit Card Authorization Form        
              <v-spacer />
            </v-card-title>
  
            <v-text-field
              label="Name"
              v-model="name"
            />
            <v-text-field
              label="Billing Address"
              v-model="payload.address"
            />
            <v-row>
              <v-col>
                <v-text-field
                  label="City"
                  v-model="payload.city"
                />
              </v-col>
              <v-col>
                <v-text-field
                  label="State"
                  v-model="payload.state"
                />
              </v-col>
            </v-row>
            <v-text-field
              label="Telephone"
              v-model="payload.phone.num"
            />
            <v-text-field
              label="Email"
              v-model="payload.email"
            />
          </v-container>
          <v-checkbox
            v-model="payload.rentCharges"
            on disabled
            label="Rent Charges"
            :rules="requiredRule"
          />
          <v-checkbox
            v-model="payload.fees"
            on disabled
            label="Misc Charges and Fees"
            :rules="requiredRule"
          />
          <v-checkbox
            v-model="payload.authorize"
            on disabled
            label="I authorize the above charge types against my credit card"
            :rules="requiredRule"
          />
        </v-form>
            <v-card-actions class="justify-center">
              <v-btn
                @click="createSignerDocument"
                class="mb-5"
                :disabled="requiredFields"
              >
                Confirm
              </v-btn>
            </v-card-actions>
          <v-overlay :value="loading">
            <v-progress-circular
              indeterminate
              size="64"
            />
          </v-overlay> 
      </v-card>
    </v-container>
  </template>
  
  
  <script>
  import RsAnvilApi from '../api/rsAnvilApi';
 
  export default {
    name: 'GenerateDocument',
    props: {
      customerId: { type: String, required: true },
      customer: { type: Object, default: () => {} },
      card: { type: Object, default: () => {} },
    },
    async mounted() {
      this.name = `${this.customer.firstName} ${this.customer.lastName}`;
      this.payload.address = this.customer.address;
      this.payload.city = this.customer.city;
      this.payload.state = this.customer.state;
      this.payload.phone.num = this.customer.phone;
      this.payload.email = this.customer.email;
      this.payload.phone.region = this.customer.country.toUpperCase();
      this.payload.phone.baseRegion = this.customer.country.toUpperCase();
      this.payload.name.firstName = this.customer.firstName;
      this.payload.name.mi = '';
      this.payload.name.lastName = this.customer.lastName;
      this.payload.last4 = this.card.creditCard.last4;
    },
    data() {
      return {
        loading: false,
        name: '',
        payload: {
            last4: '',
            email: null,
            phone: {
              num: '',
              region: 'US',
              baseRegion: 'US'
            },
            name: {
              firstName: '',
              mi: '',
              lastName: ''
            },
            city: '',
            state: '',
            address: '',
            date: new Date(),
            rentCharges: true,
            fees: true,
            authorize: true,
        },
        requiredRule: [(v) => !!v || "Field is required"],
      };
    },
    computed: {
      requiredFields(){
          return !(this.payload.rentCharges && this.payload.fees && this.payload.authorize);
      }
    },
    methods: {
      async createSignerDocument() {
        this.loading = true;
        this.payload.last4 = this.card.creditCard.last4;
        const nameParts = this.name.split(' ');
        if (nameParts[0])
          this.payload.name.firstName = nameParts[0];
        
        if (nameParts.length > 2)
          this.payload.name.mi = nameParts[1];
        
        if (nameParts.length > 1)
        this.payload.name.lastName = nameParts.length > 2 ? nameParts[2] : nameParts[1];

        const anvilApi = new RsAnvilApi(this.$keycloak.token);
        const response = await anvilApi.generateAgreement({
          payload: this.payload,
          name: this.name,
          customerId: this.customerId,
        });
        const etchPacket = response.data.data.createEtchPacket;
        const signerEid = etchPacket.documentGroup.signers[0].eid;
        const documentEid = etchPacket.documentGroup.eid;
        
        const responseUrl = await anvilApi.getSignerEmbedUrl(signerEid, documentEid, this.customerId);
        const signerUrl = responseUrl.url;
        this.$emit('signer-agreement', signerUrl, etchPacket)
        this.loading = false;
      },
    },
  }
  </script>
  
  <style>
  </style>