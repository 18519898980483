<template>
  <v-container>
    <v-card
      elevation="2"
      class="py-3 text-center"
      dark
      color="#009aca"
      outlined
    >
      <v-row>
        <v-col>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="pb-3 font-weight-bold">
                Facility Name
              </v-list-item-title>
              <v-list-item-title>{{ lease.facility.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="pb-3 font-weight-bold">
                Unit Name
              </v-list-item-title>
              <v-list-item-title>{{ lease.unitsInfo.map( unit => unit.name ).toString() }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-card>
    <v-card
      elevation="2"
      class="my-5 py-3 text-center"
      outlined
      color="#E1F5FE"
    >
      <v-row>
        <v-col>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="pb-3 cyan--text text--darken-3">
                Start Date
              </v-list-item-title>
              <v-list-item-subtitle v-if="lease.start">{{ new Date(lease.start).toLocaleDateString('en-US') }}</v-list-item-subtitle>
              <v-list-item-subtitle v-else> - </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="pb-3 cyan--text text--darken-3">
                End Date
              </v-list-item-title>
              <v-list-item-subtitle v-if="lease.end">{{ new Date(lease.end).toLocaleDateString('en-US') }}</v-list-item-subtitle>
              <v-list-item-subtitle v-else> - </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="pb-3 cyan--text text--darken-3">
                Amount Due
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ Number(
                  lease.amount - lease.amountPaid) | currencySign(lease.facility)
                 }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="pb-3 cyan--text text--darken-3">
                ACH Payments Pending
              </v-list-item-title>
              <v-list-item-subtitle>
                {{
                  Number(totalPending) | currencySign(lease.facility)
                }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col v-if="lease.agreement && lease.agreementSigned">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class=" cyan--text text--darken-3">
                Lease agreement
              </v-list-item-title>
              <v-hover v-slot="{ hover }">
                <a
                  class="text-decoration-none"
                  :href="lease.agreementOnS3"
                >
                  <v-icon
                    class="mt-2"
                    :style="{ 'color': hover ? '#00E676' : '#009aca' }"
                  >
                    mdi-arrow-down-bold-box
                  </v-icon>
                </a>
              </v-hover>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: {
    lease: { type: Object, default: () => {} },
  },
  computed: {
    totalPending() {
      return this.lease.ach.map(item => item.amount).reduce((pv, nx) => pv + nx, 0);
    },
  }
} 
</script>
