<template>
  <div>
    <v-overlay
      :opacity="0.5"
      :value="loading"
    >
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-banner single-line>
      Employees
    </v-banner>
    <v-fab-transition>
      <v-dialog
        v-model="newAccountDialog"
        max-width="600px"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            align="center"
            class="ma-3"
          >
            <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              @click="addItemToAccounts"
            >
              Add 
              contacts
            </v-btn>
          </div>
        </template>
        <v-card
          elevation="2"
          outlined
          shaped
          style="margin-bottom: 10px"
          dark
          color="#1976d2e3"
        >
          <v-form v-model="valid" ref="form">
            <v-card-title>
              <v-text-field
                v-model="newAccount.name"
                label="Name"
                :disabled="editingExistingAccount"
              />
            </v-card-title>
            <v-card-text>
              <v-text-field
                v-model="newAccount.email"
                :rules="emailRules"
                label="E-mail"
                required
                :disabled="editingExistingAccount"
              />
              <v-text-field
                v-model="newAccount.phoneNumber"
                label="Phone Number"
              />
              <v-select
                multiple
                label="Information type"
                v-model="newAccount.multiAccess"
                :items="accesses"
              />
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="green" 
                v-if="!editingExistingAccount"
                :disabled="!newAccount.name || !newAccount.email"
                @click="() => saveAccountItem()"
              >
                Save
              </v-btn>
              <v-btn
                v-else
                color="green"  
                @click="() => editAccountItem()"
              >
                Edit
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-fab-transition>
    <v-container>
      <v-row>
        <v-col>
          <v-card
            :key="index"
            v-for="(item, index) of accounts"
            elevation="2"
            outlined
            class="pb-5 mb-10"
          >
            <v-row gutter="12">
              <v-col :span="12">
                <v-card-text>
                  <div class="mb-3">
                    <span v-if="item.access">
                      {{ item.access }}
                    </span>
                    <span v-else>
                      <v-chip
                        class="ma-2"
                        :key="type"
                        v-for="type of item.multiAccess"
                        color="#009aca"
                        label
                        outlined
                      >
                        {{ type }}
                      </v-chip>
                    </span>
                    <span class="float-right">
                      <v-btn
                        color="#009aca"
                        dark
                        @click="() => loadExistingAccountForNewAccountDialog(item)"
                        class="mr-3"
                      >
                        Edit
                      </v-btn>
                      <v-btn
                        color="red lighten-1"
                        @click="() => deleteAccountItem(index)"
                      >
                        Delete
                      </v-btn>
                    </span>
                  </div>
                </v-card-text>
              </v-col>
            </v-row>
            <v-divider />
            <v-row
              class="pb-5"
              gutter="12"
            >
              <v-col :span="12">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      <v-text-field
                        v-if="!item._id"
                        v-model="item.name"
                        label="Name"
                      />
                      <div v-else>
                        Name: {{ item.name }}
                      </div>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <div>
                        <div>Email: {{ item.email }}</div> 
                      </div>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <div>
                        <div>Phone Number: {{ item.phoneNumber }}</div> 
                      </div>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>   
              </v-col>
              <v-col :span="12">
                <v-card-text>
                  <div
                    v-if="item.forkLift && item.forkLift.certified"
                    class="float-right text-right"
                  >
                    <div>
                      <v-icon color="#A5D6A7">
                        mdi-checkbox-marked-circle
                      </v-icon>
                      <i class="el-icon-check icon-color" />
                      Forklift Certified
                    </div>
                    <div v-if="item.forkLift && item.forkLift.expiredAt">
                      Expiry Date: {{ new Date(item.forkLift.expiredAt).toLocaleDateString('en-US') }}
                    </div>
                  </div>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
          <v-snackbar
            v-model="snackbar"
            :timeout="2000"
          >
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
              <v-btn
                color="blue"
                text
                v-bind="attrs"
                @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { CUSTOMER_API_BASE } from "../env";

export default {
  name: "Employees",
  props: {
  customerId: { type: String, required: true },
  },
  async mounted() {
    try {
      this.initialLoading = true;
      this.loading = true;
      this.card = true;
      const customer = await axios.get(
        `${CUSTOMER_API_BASE}/customer/${this.customerId}`,
        {
          headers: {
            Authorization: `Bearer ${this.$keycloak.token}`,
          },
        }
      );
      this.customer = {
        ...customer.data,
      };
      this.accounts = this.customer.accounts;
      this.loading = false;
      this.initialLoading = false;
    } catch (err) {
      this.initialLoading = false;
      this.loading = false;
      this.error = err.response.data;
      throw new Error(err.response.data);
    }
  },
  data () {
    return {
      accesses: ["FINANCE", "WAREHOUSE", "LEASING", "EMERGENCIES"],
      snackbar: false,
      snackbarText: "",
      valid: false,
      error: "",
      loading: true,
      initialLoading: true,
      accounts: [],
      newAccountDialog: false,
      editingExistingAccount: false,
      newAccount: {
        createdBy: this.$keycloak.tokenParsed.email,
        email: "",
        name: "",
        access: "",
        phoneNumber: '',
        multiAccess: [],
      },
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
    };
  },
  methods: {
    addItemToAccounts() {
      this.newAccount = {
        createdBy: this.$keycloak.tokenParsed.email,
        email: "",
        name: "",
        access: "",
        phoneNumber: '',
        multiAccess: [],
      };
      this.editingExistingAccount = false;
    },
    async deleteAccountItem(index) {
      this.loading = true;
      if (!this.accounts[index]._id) {
        this.accounts.splice(index, 1);
        this.loading = false;
        return;
      }
      this.accounts.splice(index, 1);
      const response = await axios.put(
        `${CUSTOMER_API_BASE}/customer/${this.customerId}`,
        {
          accounts: this.accounts,
        },
        {
          headers: {
            Authorization: `Bearer ${this.$keycloak.token}`,
          },
        }
      );

      this.accounts = response.data.accounts;

      this.loading = false;
      this.snackbar = true;
      this.snackbarText = "Successfully deleted employee";
    },
    async saveAccountItem() {
      this.loading = true;

      const valid = this.$refs.form.validate();
      if (!valid) {
        this.snackbar = true;
        this.snackbarText = "Enter a valid email!";
        this.loading = false;
        return;
      }

      if (!this.newAccount.email && !this.newAccount.name) {
        this.snackbar = true;
        this.snackbarText = "Email and Name required!";
        this.loading = false;
        return;
      }

      if (this.newAccount.multiAccess.length === 0 && !this.newAccount.access) {
        this.snackbar = true;
        this.snackbarText = "Please choose the access type.";
        this.loading = false;
        return;
      }

      const existingAccounts = this.accounts.filter((item) => {
        if (item._id) {
          return item;
        }
      });

      const existingEmails = existingAccounts.map((item) => item.email);

      if (existingEmails.includes(this.newAccount.email)) {
        this.snackbar = true;
        this.snackbarText = "Email already exists";
        this.loading = false;
        return;
      }
   
      this.newAccountDialog = false;

      const response = await axios.put(
        `${CUSTOMER_API_BASE}/customer/${this.customerId}`,
        {
          accounts: [...this.accounts, this.newAccount],
        },
        {
          headers: {
            Authorization: `Bearer ${this.$keycloak.token}`,
          },
        }
      );

      this.accounts = response.data.accounts;
      this.loading = false;
      this.snackbar = true;
      this.snackbarText = "Successfully added employee";
    },
    loadExistingAccountForNewAccountDialog(account) {
      this.editingExistingAccount = true;
      this.customer.accounts.forEach((item) => {
        if (item._id === account._id) {
          this.newAccount = { ...account };
        }
      });
      this.newAccountDialog = true;
      this.loading = false;
    },
    async editAccountItem() {
      this.loading = true;
      const accounts = this.customer.accounts.filter((item) => {
        if (item._id !== this.newAccount._id) {
          return item;
        }
      });

      this.newAccountDialog = false;

      accounts.unshift(this.newAccount);
       try {
        const response = await axios.put(
         `${CUSTOMER_API_BASE}/customer/${this.customerId}`,
          {
            accounts,
          },
          {
           headers: {
              Authorization: `Bearer ${this.$keycloak.token}`,
            },
          },
        );
        this.accounts = response.data.accounts;
        this.loading = false;
        this.snackbar = true;
        this.snackbarText = "Successfully updated employee";

      } catch (err) {
        this.loading = false;
        this.snackbar = true;
        this.snackbarText = err.message;
        this.error = err.response.data;
      }
    },
  },
};
</script>

<style scoped>
.loader {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 10%;
}
</style>
