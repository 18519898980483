<template> 
  <v-card>
    <v-card align="right"> 
      <v-btn
        icon
        color="pink"
      >
        <v-icon @click="completeDialog">mdi-close</v-icon>
      </v-btn>
    </v-card>
    <v-stepper
      alt-labels
      v-model="activeStep"
      color="success"
    >
      <v-stepper-header>
        <v-stepper-step
          :complete="activeStep > 1"
          step="1"
        >
          Select Payment Type
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          :complete="activeStep > 2"
          step="2"
        >
          Make Payment
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          step="3"
        >
          Done
        </v-stepper-step>
      </v-stepper-header>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-container fluid>
              <v-row v-show="customerDetails.blockAchPayments">
                <v-col>
                  <v-alert border="bottom"
                    colored-border
                    type="warning"
                    elevation="2">
                    ACH payments are currently disabled on your account. Please contact your facility manager for more information.
                  </v-alert>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="6">
                  <v-subheader class="text-subtitle-1 dark">
                    Payment Type:
                  </v-subheader>
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-model="mode"
                    label="Select Payment Type"
                    :items="paymentMode"
                  />
                </v-col>
              </v-row>
              <v-row justify="center" align="center">
                <v-btn
                  :disabled="modeSelected"
                  color="primary"
                  @click="activeStep = 2"
                  class="mt-9 mb-4"
                >
                  Next
                </v-btn>
              </v-row>
            </v-container>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-container fluid>
              <v-row v-show="mode === 'ACH'">
                <v-col>
                  <v-alert border="bottom"
                    colored-border
                    type="warning"
                    elevation="2">
                    ACH payments may take up to 3 business days to clear your account.
                    They will not be reflected in your account balance or as a completed payment during the settlement process.
                    Upon successful clearing, the amount will be posted to your account automatically.
                  </v-alert>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="4">
                  <v-subheader class="text-subtitle-1 dark">
                    Payment Account:
                  </v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-select
                    v-if="mode === 'ACH' && braintreeCustomer.usBankAccounts"
                    label="Saved Accounts"
                    :items="getBraintreePaymentMethods"
                    :item-text="getItems"
                    :item-value="getItemValues"
                    :rules="bankRules"
                    v-model="selectedPaymentMethod"
                  />
                  <v-select
                    v-else-if="mode === 'Credit Card'" 
                    label="Select Credit Card"
                    :items="getCreditCardPaymentMethods"
                    :item-text="getItems"
                    :item-value="getItemValues"
                    :rules="creditCardRules"
                    v-model="selectedPaymentMethod"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-subheader class="text-subtitle-1 dark">
                    Amount:
                  </v-subheader>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    type="number"
                    :rules="numberRules"
                    v-model.number="originalAmount"
                    prefix="$"
                  >
                    {{ Number(originalAmount).toFixed(2) }}
                  </v-text-field>
                </v-col>
              </v-row>
              <v-list dense>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Amount Due</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-chip
                        color="red accent-1"
                        label
                      >
                        $ {{ Number(lease.amount - lease.amountPaid).toFixed(2) }}
                      </v-chip>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title>
                    Total Charged: ${{ Number(getCreditCardFee() + originalAmount).toFixed(2) }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ mode }} Fee: ${{ Number(getCreditCardFee()).toFixed(2) }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    Amount Applied to Account: ${{ Number(originalAmount).toFixed(2) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-row justify="center" align="center">
                <template>
                  <div>
                    <v-btn
                      color="primary"
                      @click="addTransaction"
                      class="ma-4"
                    >
                      Pay
                    </v-btn> 
                    <v-snackbar
                      v-model="snackbar"
                    >
                      {{ snackbarText }}
                    </v-snackbar>
                  </div>
                </template>
                <v-btn
                  @click="() => activeStep = 1"
                  class="ma-4"
                >
                  Back
                </v-btn>
              </v-row>
            </v-container>
          </v-stepper-content>
          <v-stepper-content step="3">
            <v-container fluid>
              <v-overlay :opacity="0.5" :value="loading">
                <v-progress-circular color="primary" indeterminate size="64"> </v-progress-circular>
              </v-overlay>
              <v-snackbar
                      v-model="snackbar"
                    >
                      {{ snackbarText }}
                    </v-snackbar>
              <v-row justify="center" align="center" class="mt-2">
                <v-icon v-if="!loading" :color="changeIcon ? 'success' : 'warning'" size="52" class="mr-2">
                  {{ getAlertIcon() }}
                </v-icon>
              </v-row>
              <v-row justify="center" align="center" class="mt-10">
                <v-col cols="8">
                  <v-alert 
                    v-if="!loading && moneyAddedStatus"
                    max-height="200"
                    text
                    type="success"
                  >
                    Amount added Successfully
                  </v-alert>
                  <v-alert 
                    v-else-if="!loading && !moneyAddedStatus"
                    max-height="200"
                    text
                    type="warning"
                  >
                    {{ getTransactionMessage() }}
                  </v-alert>
                </v-col>
              </v-row>
              <v-row justify="center" align="center" class="text-caption mb-5" v-if="transactionMessage === 'Settlement Pending'">
                If successful, this payment will post to your account in about 4 business days.
              </v-row>
              <v-row v-if="!loading" justify="center" align="center">
                <v-btn
                  @click="completeDialog"
                  class="ma-4"
                >
                  Done
                </v-btn>
                <v-btn
                  v-if="!moneyAddedStatus && showButton"
                  color="warning"
                  @click="() => activeStep = 2"
                  class="ma-4"
                >
                  Back
                </v-btn>
              </v-row>
            </v-container>
          </v-stepper-content>
        </v-stepper-items>
      </v-form>
    </v-stepper>
  </v-card>
</template>

<script>
import axios from "axios";
import { CUSTOMER_API_BASE, PG_API_BASE } from "../env";

export default {
  props: {
  customerId: { type: String, required: true },
  lease: { type: Object, default: () => {} },
  customerDetails: { type: Object, default: () => {} },
  },
  async mounted() {
    try {
      const braintreeAccount = await this.getCustomerFromBraintree();
        if (!braintreeAccount) {
          this.braintreeAccount = false;
        } else {
          this.braintreeAccount = true;
        }

        if(this.braintreeCustomer && this.braintreeCustomer.usBankAccounts && this.braintreeCustomer.usBankAccounts.length > 0){
          this.braintreeCustomer.usBankAccounts.map(item => {
            if(item.verified && !this.customerDetails.blockAchPayments){
              this.paymentMode.push('ACH');
            }
          })
        }

        if(this.braintreeCustomer && this.braintreeCustomer.creditCards && this.braintreeCustomer.creditCards.length > 0){
          this.paymentMode.push('Credit Card');
        }
    } catch (error) {
       this.loading = false;
       this.braintreeAccount = false;
    }
  },
  computed: {
    getBraintreePaymentMethods() {
     return this.braintreeCustomer.usBankAccounts.reduce((result, item) => {
       if (item.verified) {
         result.push({
           key: item.token,
           type: item.bankName,
           last4: item.last4
         });
       }
       return result;
    }, []);
    },
    getCreditCardPaymentMethods() {
      const cardType = this.braintreeCustomer.creditCards.map(item => ({ key: item.token, type: item.cardType, last4: item.last4 }));
      return cardType;
    },
    modeSelected() {
      let valid = true;
      if(this.mode){
        valid = false;
      }
      return valid;
    }
  },
  data () {
    return {
      loading: false,
      braintreeAccount: false,
      snackbar: false,
      snackbarText: "",
      transactionMessage: '',
      changeIcon: false,
      moneyAddedStatus: false,
      showButton: false,
      mode: null,
      paymentMode: [],
      activeStep: 1,
      selectedPaymentMethod: null,
      braintreeCustomer: null,
      originalAmount: 0,
      amountPaid: 0,
      numberRules: [
        v => !!v || 'Number is required',
      ],
      bankRules: [
        v => !!v || 'Please select a bank account'
      ],
      creditCardRules: [
        v => !!v || 'Please select a credit card'
      ]
    };
  },
  methods: {
    getTransactionMessage() {
      if (!this.transactionMessage) {
        return 'Something went wrong';
      } else if (this.transactionMessage === 'Settlement Pending') {
        return 'Transaction Successfully Submitted'
      } else {
        return this.transactionMessage;
      }
    },
    getAlertIcon() {
      if (this.changeIcon) {
        return 'mdi mdi-check-circle';
      } else if (this.transactionMessage == 'Settlement Pending') {
        return 'mdi mdi-timer-sand'; 
      } else {
        return 'mdi mdi-alert';
      }
    },
    getItems(item){
      return `${item.type} - ${item.last4}`
    },
    getItemValues(item) {
      return item;
    },
    async getCustomerFromBraintree() {
      try {
        const response = await axios.get(
          `${PG_API_BASE}/customer/${this.customerId}`,
          {
           headers: {
              Authorization: `Bearer ${this.$keycloak.token}`,
            },
          }
        );
        this.braintreeCustomer = response.data.customer;
        return response.data.customer; 
      } catch (err) {
          return null;
      }
    },
    completeDialog() {
      this.mode='';
      this.activeStep = 1;
      this.$emit('completed');
      this.$refs.form.reset();
    },
    getCreditCardFee() {
      if (this.mode === 'Credit Card') {
        if (
          this.lease.facility.creditCardFee
          && this.lease.facility.creditCardFee.enabled
        ) {
          this.amountPaid = (
            this.originalAmount
            + (this.originalAmount
              * (this.lease.facility.creditCardFee.fee / 100)
            )
          );

          return Number(Number((this.originalAmount
              * (this.lease.facility.creditCardFee.fee / 100)
          )).toFixed(2));
        }
        this.amountPaid = this.originalAmount;
        return 0;
      }
      return 0;
    },
    async addTransaction() {
       try {
        if (this.originalAmount === 0) {
          this.snackbar = true;
          this.snackbarText = 'Amount should be greater than 0.'
          return;
        }
        if (!this.selectedPaymentMethod) {
          this.snackbar = true;
          this.snackbarText = 'Please select a payment method.'
          return;
        }

        this.activeStep ="3";
        this.loading = true;
        const response = await axios.post(
          `${CUSTOMER_API_BASE}/customer/wallet/${this.customerId}/${this.lease.facility._id}/payment`,
          {
            transaction: {
              createdAt: new Date(),
              invoice: this.lease._id,
              source: this.selectedPaymentMethod.last4,
              facility: this.lease.facility._id,
              customer: this.customerId,
              type: this.mode === 'Credit Card' ? 'CREDIT_CARD' : 'ACH',
              createdBy: this.$keycloak.tokenParsed.email,
              amount: this.originalAmount,
              ...(this.mode === 'Credit Card'
                ? {
                 enabledCreditCardFee: this.lease.facility.creditCardFee.enabled,
                 creditCardFee: this.getCreditCardFee(),
                }
                : {}
              ),
            },
          lease: this.lease,
          defaultPayment: {
            paymentType: this.mode,
            token: this.selectedPaymentMethod.key,
          }
          },
          {
           headers: {
              Authorization: `Bearer ${this.$keycloak.token}`,
            },
          }
        );

        if (response.data.result.transaction.events[1].status === 'pending') {
          this.snackbar = true;
          this.snackbarText = 'Settlement Pending! When amount is settled it will be reflected on the wallet'
          this.transactionMessage = 'Settlement Pending'
          this.loading = false;
          this.moneyAddedStatus = false;
          this.showButton = false;
          console.log(this.showButton);
          return;
        }

        if (!response.data.result.transaction.success) {
          this.snackbar = true;
          this.snackbarText = 'Transaction failed'
          this.moneyAddedStatus = false;
          this.showButton = true;
          this.loading = false;
          return;
        }

       this.moneyAddedStatus = true;
       this.changeIcon = true;
       this.snackbar = true;
       this.snackbarText = 'Amount is added successfully'
       this.loading = false;
      } catch (err) {
         console.log(err);
         this.moneyAddedStatus = false;
         this.showButton = true;
         this.loading = false;
      }
    },
  }
} 
</script>

<style>
@media only screen and (max-width: 959px) {
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Chrome */
    display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */ !important;
  }
  @media only screen and (max-width: 959px) {
    .v-stepper:not(.v-stepper--vertical) .v-stepper__step__step {
      margin-right: 12px;
    }
  }
}
</style>
