<template>
  <v-container>
    <v-card :loading="loading">
      <v-data-table :headers="headers" :items="receipts" item-key="walletTransaction" hide-default-footer>
        <template v-slot:item="props">
          <tr>
          <td style="padding: 1rem">{{ props.item.amount | currencySign(props.item.facility) }}</td>
            <td>
              <v-btn icon color="blue" @click="() => openPdfDialog(props.item.url)">
                <v-icon>
                  mdi-eye
                </v-icon>
              </v-btn>
              <a :href="props.item.url">
                <v-btn icon><v-icon>mdi-download</v-icon></v-btn>
              </a>
            </td>
          </tr>
        </template>
        <template v-slot:no-data>
          No Receipts found
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="receiptDialog">
      <PreviewPdf v-if="currentReceiptLink" :source="currentReceiptLink" />
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios';
import { CUSTOMER_API_BASE } from '../env';
import PreviewPdf from './PreviewPdf';

export default {
  name: 'PaymentReceipts',
  components: { PreviewPdf },
  props: { customerId: { type: String, required: true } },
  data: function () {
    return {
      receipts: [],
      headers: [
        { text: 'Amount', value: 'amount' },
        { text: 'Document', value: 'url' },
      ],
      receiptDialog: false,
      loading: false,
      currentReceiptLink: null,
    };
  },
  async mounted() {
    this.loading = true;
    try {
      const response = await axios.get(
        `${CUSTOMER_API_BASE}/customer/receipts/${this.customerId}`,
        {
          headers: {
            Authorization: `Bearer ${this.$keycloak.token}`,
          },
        });
      this.receipts = response.data;
    } catch (e) {
      this.loading = false;
    }
    this.loading = false
  },
  methods: {
    openPdfDialog(source) {
      this.receiptDialog = true;
      this.currentReceiptLink = source;
    },
  },
}
</script>
