<template>
    <iframe
      :src="url"
      frameborder="0"
      width="100%" height="500px"
    >
    </iframe>
</template>

  <script>
  export default {
    name: 'CreditCardAnvil',
    props: {
      url: { type: String, required: true },
    },
    mounted() {
      window.addEventListener('message', ({ origin, data }) => {
        if (origin !== 'https://app.useanvil.com') return;
        if (data && typeof data === 'object') {
          // You will receive the event object when the signing process is complete, or
          // the user sees an error. This object will contain several properties:
          // `action`, `signerStatus`, `signerEid`, `nextSignerEid`, `documentGroupStatus`,
          // `documentGroupEid`, `etchPacketEid`, `weldDataEid`.
          //
          // e.g.
          //
          // {
          //  action: 'signerComplete',
          //  signerStatus: 'completed',
          //  signerEid: 'Jc1ZJisPnpF5lHyfyqBW',
          //  nextSignerEid: 'WBqyfyHl5FpnPsiJZ1cJ',
          //  documentGroupStatus: 'partial',
          //  documentGroupEid: 'nEKq2eGim0ijSqKd98nG',
          //  etchPacketEid: 'XzfmVPfGUEyBc1XyylFo',
          // }
          //
          // When there is an error, you will also receive `errorType`, `error`, and
          // `message` properties.
          //
          // You do not need to trigger a redirect, the iframe will make the redirect.
          if (data.action === 'signerComplete') {
            // A signer has finished signing
            this.$emit('completed');
          } else if (data.action === 'signerError') {
            // A signer has experienced an error
            this.$emit('error', data.error);
          }
        }
      });
    },
  };
  </script>

