<template>
  <v-timeline
    dense
  >
    <v-timeline-item
      :key="item._id"
      v-for="item in [...lease.lineItems].reverse()"
      :color="timeLineBackgroundColor(item)"
      small
      :icon="timeLineIcon(item)"
      fill-dot
    >
      <v-card 
        :color="lineItemBackgroundColor(item)"
        :loading="loading"
        style="padding: 0.5rem"
      >
        <div class="descriptions">
          <div class="description">
            <span class="label">Description:</span>
            {{ item.description }}
          </div>
          <div class="description tags-wrapper" style="justify-content: right">
            <v-chip v-if="item.voided" color="red" >VOIDED</v-chip>
            <v-chip v-if="item.refunded" color="red"  label>REFUNDED</v-chip>
            <v-chip v-if="item.details === 'RENT'" color="#009aca" class="white--text" label>RENT</v-chip>
            <v-chip v-if="item.details === 'TAX'" color="#159895" class="white--text" label>TAX</v-chip>
            <v-chip v-if="item.details === 'FEES'" color="#1A5F7A" class="white--text" label>FEES</v-chip>
            <v-chip v-if="item.details === 'DEPOSIT'" color="#002B5B" class="white--text" label>SECURITY DEPOSIT</v-chip>
            <v-chip v-if="item.type === 'DEBIT' && item.transactionType !== 'ADJUSTMENT' && !item.refunded" label color="green" >PAYMENT</v-chip>
            <v-chip v-if="item.type === 'DEBIT' && item.transactionType === 'ADJUSTMENT'" label color="orange" >ADJUSTMENT</v-chip>
          </div>
        </div>
        <div class="descriptions">
          <div class="description">
            <span class="label">Amount:</span>
            {{ Number(item.amount || 0).toFixed(2) | currencySign(lease.facility) }}
          </div>
          <div class="description">
            <span class="label">Account Balance:</span>
            {{ Number(item.accountBalance || 0).toFixed(2) | currencySign(lease.facility) }}
          </div>
          <div class="description">
            <span class="label">Period:</span>
            {{ item.period ? getMonthAndYear(item.period) : '-' }}
          </div>
          <div class="description">
            <span class="label">Due Date:</span>
            {{ item.dueDate ? new Date(item.dueDate).toLocaleDateString('en-US') : '-' }}
          </div>
        </div>
      </v-card>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
import getMonth from "../utils/timing"

export default {
  props: {
    lease: { type: Object, default: () => {} },
  },
  data () {
    return {
      loading: false,
    };
  },
 methods: {
   getMonthAndYear(value) {
      return getMonth(value);
    },
    lineItemBackgroundColor(lineItem) {
      if (lineItem.voided) {
        return'#FFCDD2';
      } else if (lineItem.transactionType === 'ADJUSTMENT'){
        return '#F5C58C';
      }
        return '#F5F5F5';
    },
   timeLineBackgroundColor(lineItem) {
      if (lineItem.voided) {
        return '#FF1744';
      } else if (lineItem.type === 'DEBIT' && lineItem.transactionType !== 'ADJUSTMENT') {
        return '#00E676';
      } else if (lineItem.transactionType === 'ADJUSTMENT'){
        return '#F5C58C'; 
      }
        return '#009aca';
    },
   timeLineIcon(lineItem) {
      if (lineItem.voided) {
        return 'mdi-close-circle';
      } else if (lineItem.type === 'DEBIT' && lineItem.transactionType !== 'ADJUSTMENT') {
        return 'mdi-checkbox-marked-circle';
      } 
      return ;
    },
  }
} 
</script>

<style scoped>
.descriptions {
  display: flex;
  align-content: space-between;
  flex-wrap: wrap;
}
.description {
  display: flex;
  align-items: center;
  color: #757575;
  flex-grow: 1;
  font-family: sans-serif;
  align-self: stretch;
  padding: 0 0.25rem;
}
.description .label {
  font-weight: bold;
  margin-right: 0.5rem;
}
.tags-wrapper {
  display: flex;
  flex-direction: row;
  gap: 0 0.25rem;
}
</style>
