<template>
  <v-container>
    <v-overlay
      :opacity="0.5"
      :value="loading"
    >
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <div class="my-5">
      <v-row>
        <v-col  
          v-for="item in customerWallet"
          :key="item._id"
          v-show="Math.abs(item.amountPaid - item.amount) > 0"
        >
          <template>
            <v-card
              class="py-8 text-center"
              outlined
              dark
              color="#009aca"
              :loading="loading"
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="my-5">
                    Facility Code
                  </v-list-item-title>
                  <v-list-item-title>
                    {{ item.facility.code }} 
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="my-5">
                    Amount in Wallet
                  </v-list-item-title>
                  <v-list-item-title>
                    {{ Number(
                      item.amountPaid - item.amount
                    ) | currencySign(item.facility) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </template>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      v-model="addMoneyDialog"
      width="700"
      persistent
    >
      <AddMoneyToWallet 
        :customer-id="customerId"
        @completed="closeAddMoneyDialog"
        :lease="selectedInvoice"
        :customer-details="customerDetails"
      />
    </v-dialog>
    <div
      v-if="leases.length > 0"
      class="account"
    >
      <v-card>
        <template>
          <v-container
            v-for="lease of leases"
            :key="lease._id"
          >
            <v-chip
              v-if="!lease.future"
              class="white--text mr-3"
              label
              color="green"
            >
              Active Lease
            </v-chip>
            <v-chip
              v-if="lease.future"
              class="white--text mr-3"
              label
              color="pink"
            >
              Future Lease
            </v-chip>
            <v-divider />
            <div v-if="lease.amount - lease.amountPaid > 0 && !lease.future">
              <v-alert
                :prominent="lease.ach && !lease.ach.map(item => item.amount).reduce((pv, nx) => pv + nx, 0) > 0"
                :type="lease.ach && !lease.ach.map(item => item.amount).reduce((pv, nx) => pv + nx, 0) > 0 ? 'error' : 'warning'"
              >
                <v-row align="center">
                  <v-col class="grow" v-if="lease.ach && !lease.ach.map(item => item.amount).reduce((pv, nx) => pv + nx, 0) > 0">
                    Please clear the dues for unit {{ lease.unitsInfo.map( unit => unit.name ).join(', ')
                    }}
                  </v-col>
                  <v-btn 
                    v-if="braintreeAccountExist && !customerDetails.blockPayments"
                    color="#B9F6CA"
                    class="cyan--text text--darken-3 font-weight-bold mr-5"
                    @click="() => openAddMoneyDialog(lease)"
                  >
                    Make a Payment
                  </v-btn>
                  <v-btn 
                    v-else
                    color="#B9F6CA"
                    class="cyan--text text--darken-3 font-weight-bold mr-5"
                    :disabled="customerDetails.blockAchPayments"
                    @click="() => $emit('change', 2)"
                  >
                    Add a Payment Method
                  </v-btn>
                </v-row>
              </v-alert>
            </div>
            <ActiveLease 
              :customer-id="customerId"
              :lease="lease"
            />
            <v-divider />
          </v-container>
        </template>
      </v-card>
    </div>
    <div
      class="pt-3"
      v-else-if="!initialLoading"
    >
      <v-card
        elevation="2"
        class="py-8 text-center"
        outlined
      >
        No Active Lease found!
      </v-card>
    </div>
  </v-container>
</template>
  
<script>
import axios from "axios";
import { CUSTOMER_API_BASE } from "../env";
import AddMoneyToWallet from "./AddMoneyToWallet.vue";
import ActiveLease from "./ActiveLease.vue";

export default {
  name: 'Lease',
  props: { braintreeCustomer: { type: Object, default: () => ({}) } },
  components: {
   AddMoneyToWallet,
   ActiveLease ,
  },
  async mounted() {
    try {
     this.initialLoading = true;
     this.loading = true;
     const { customer } =
      this.$keycloak.tokenParsed;
     this.customerId = customer[0];
     await this.getCustomerActiveLease();
     await this.getPendingACHTransactions();
     await this.getCustomerWallet();
     await this.getCustomerDetails();
     this.braintreeAccount = !!this.braintreeCustomer;
    this.loading = false;
    this.initialLoading = false;
   } catch (err) {
     this.initialLoading = false;
     this.loading = false;
     this.braintreeAccount = false;
     this.error = err.response.data;
     throw new Error(err.response.data);
   }
  },
  computed: {
    totalAmountPaid() {
      let amountPaid = 0;
      this.rentalInfo.forEach((lease) => {
        amountPaid = amountPaid + lease.amountPaid;
      })
      return amountPaid;
    },
    totalAmount() {
      let amount = 0;
      this.rentalInfo.forEach((lease) => {
       amount = amount + lease.amount;
      })
      return amount;
    },
    braintreeAccountExist() {
    if (this.braintreeAccount && this.braintreeCustomer.paymentMethods && this.braintreeCustomer.paymentMethods.length > 0) {
     return true;
    } 
     return false;
   }
  },
 data () {
   return {
     loading: true,
     customerId: null,
     rentalInfo: [],
     leases: [],
     achPending: [],
     braintreeAccount: false,
     selectedInvoice: [],
     initialLoading: true,
     addMoneyDialog: false,
     customerDetails: [],
     customerWallet: [],
   };
  },
 methods: {
  async getPendingACHTransactions() {
    const response = await axios.get(`${CUSTOMER_API_BASE}/customer/pending/transactions/${this.customerId}`, {
      headers: {
        Authorization: `Bearer ${this.$keycloak.token}`,
      }
    });

    this.achPending = response.data;
    this.rentalInfo.forEach( rental => {
      const { facility } = rental;
      const facilityAchTransactions = this.achPending.filter( item => item.facility === facility._id );

      rental.ach = facilityAchTransactions;
    });

    this.leases = [...this.rentalInfo];
  },
  async getCustomerActiveLease() {
    const response = await axios.get(
      `${CUSTOMER_API_BASE}/customer/invoices/${this.customerId}`,
        {  
          headers: {
            Authorization: `Bearer ${this.$keycloak.token}`,
          },
        }
      );
    this.rentalInfo = response.data;
  }, 
  openAddMoneyDialog(inv){
    this.addMoneyDialog = true;
    this.selectedInvoice = inv;
  },
  async closeAddMoneyDialog() {
    this.addMoneyDialog = false;
    this.loading = true;
    await this.getCustomerActiveLease();
    await this.getPendingACHTransactions();
    this.loading = false;
   },
  async getCustomerDetails() {
    try {
      const response = await axios.get(
       `${CUSTOMER_API_BASE}/customer/${this.customerId}`,
       {
         headers: {
           Authorization: `Bearer ${this.$keycloak.token}`,
          },
        }
      );
      this.customerDetails = response.data;
      return response.data 
    } catch (err) {
      this.loading = false;
      return null;
    }
   },
  async getCustomerWallet() {
    try {
      const response = await axios.get(
       `${CUSTOMER_API_BASE}/customer/wallet/${this.customerId}`,
       {
         headers: {
           Authorization: `Bearer ${this.$keycloak.token}`,
          },
        }
      );
      this.customerWallet = response.data;
    } catch (err) {
      this.loading = false;
      return null;
    }
   }, 
  }
} 
</script>
