import axios from 'axios';
import { RS_ANVIL_API, ANVIL_TEMPLATE_ID } from '../env';

const axiosInstance = axios.create({ baseURL: RS_ANVIL_API, withCredentials: true });

export default class RsAnvilApi {
  constructor(token) {
    this.token = token;
  }

  async generateAgreement({ payload, name, customerId }) {
    const response = await axiosInstance.post(`/crm/sign/pdf/${ANVIL_TEMPLATE_ID}/${customerId}`, {
        payload: {
            title: 'ReadySpaces Credit Card Authorization',
            fontSize: 10,
            textColor: '#333333',
            data: payload,
          },
        name,
        signers: [
          {
            id: 'signer',
            name,
            email: payload.email,
            signerType: 'embedded',
            enableEmails: false,
            routingOrder: 1,
            fields: [
              { fileId: name, fieldId: 'sign' },
            ],
          },
        ],
    },   
    {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
    return response.data;
  }
  async getSignerEmbedUrl(signerEid, id, customerId) {
    const response = await axiosInstance.get(`crm/generate/sign/url/${signerEid}/${id}/${customerId}`, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
    return response.data;
  }
  async downloadAgreement(eid, customerId) {
    const response = await axiosInstance.get(`crm/documents/download/${eid}/${customerId}`, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });

    return response.data;
  }
  async getSignersStatus(documentGroupEid, customerId) {
    const response = await axiosInstance.get(`crm/signers/${documentGroupEid}/${customerId}`, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
    return response.data;
  }
}
