<template>
  <v-card>
    <v-card-title class="headline">Receipt</v-card-title>
    <pdf
      v-if="source"
      :src="source"
      :page="page"
      @num-pages="totalPages = $event"
      @link-clicked="page = $event"
    />
  </v-card>
</template>

<script>
import pdf from 'vue-pdf';

export default {
  name: 'PreviewPdf',
  components: { pdf },
  props: { source: { type: String, required: true } },
  data() {
    return {
      totalPages: 0,
      page: 1,
    };
  },
}
</script>
