<template>
  <v-container>
    <LeaseHeader 
      :lease="lease"
    />
    <LeaseLineItem
      :lease="lease"
    />
  </v-container>
</template>

<script>
import LeaseHeader from "@/components/LeaseHeader.vue";
import LeaseLineItem from "@/components/LeaseLineItem.vue";

export default {
  props: {
    lease: { type: Object, default: () => {} },
  },
  components: { LeaseHeader, LeaseLineItem },
} 
</script>
