<template>
  <div class="account">
    <v-alert v-model="showError" dismissible border="top" type="error" dark>
      {{ error }}
    </v-alert>
    <v-snackbar v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <v-card v-if="!initialLoading">
      <v-tabs v-model="tab">
        <v-tab> Lease </v-tab>
        <v-tab> Info </v-tab>
        <v-tab> Payment Methods </v-tab>
        <v-tab> Payment Receipts </v-tab>
        <v-tab> Employees </v-tab>
        <!-- <v-tab v-if="card"> Add Card </v-tab> -->
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <Lease :braintreeCustomer="braintreeCustomer" @change="changeTab" />
        </v-tab-item>
        <v-tab-item>
          <v-overlay :opacity="0.5" :value="loading">
            <v-progress-circular indeterminate size="64" />
          </v-overlay>
          <v-form v-model="valid" ref="form">
            <v-banner single-line>
              Company Details
            </v-banner>
            <v-container>
              <v-row>
                <v-col cols="12" md="4">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <v-text-field :value="company" label="Company Name" disabled />
                      </div>
                    </template>
                    <span>Please contact your General Manager to update this information.</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="customer.phone" label="Phone Number" :rules="requiredRules" required />
                </v-col>
                <v-col cols="12" md="4">
                  <v-select v-model="customer.country" :items="countries" label="Country" item-text="name"
                    item-value="value" @change="resetZip" disabled />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="customer.address" label="Address Line 1" disabled />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="customer.address2" label="Address Line 2" disabled />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="customer.city" label="City" disabled />
                </v-col>
                <v-col cols="12" md="4">
                  <State :country="customer.country" :given-state="customer.state"
                    @change="(state) => (customer.state = state)" />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="customer.zip" label="Zip" :rules="zipRules" disabled />
                </v-col>
                <v-col cols="12" md="4">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <v-text-field :value="tax" label="Tax No" disabled />
                      </div>
                    </template>
                    <span>Please contact your General Manager to update this information.</span>
                  </v-tooltip>
                </v-col>
                <!-- <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="customer.business"
                    label="Business Name"
                  />
                </v-col> -->
              </v-row>
              <v-btn class="mr-4" @click="submit">
                Save
              </v-btn>
            </v-container>
            <v-banner single-line>
              Primary Account
            </v-banner>
            <v-container>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field v-model="customer.firstName" :rules="nameRules" :counter="20" label="First name" />
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field v-model="customer.lastName" :rules="nameRules" :counter="20" label="Last name" />
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field v-model="customer.email" :rules="emailRules" label="E-mail" disabled required />
                </v-col>
              </v-row>
              <v-btn class="mr-4" @click="submitPrimaryContact">
                Save
              </v-btn>
            </v-container>
          </v-form>
          <v-banner single-line>
            Password Reset
          </v-banner>
          <template>
            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-text-field v-model="password" label="Password"
                      :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="passwordVisible ? 'text' : 'password'"
                      @click:append="togglePasswordVisibility"></v-text-field>
                  </v-col>
                </v-row>
                <v-btn :disabled="!password" @click="resetPassword">
                  Reset Password
                </v-btn>
              </v-container>
            </v-form>
          </template>
        </v-tab-item>
        <v-tab-item>
          <v-overlay :opacity="0.5" :value="loading">
            <v-progress-circular indeterminate size="64" />
          </v-overlay>
          <v-subheader> Braintree linked account </v-subheader>
          <v-row no-gutters>
            <v-col offset-md="1" v-show="braintreeCustomer.paymentMethods.length">
              <v-card class="pa-3 my-3 mr-12" outlined elevation="2">
                <p class="font-weight-bold">Auto-pay</p>
                <v-switch :input-value="savedCustomerData.autopay" @change="updateAutopay" label="Enable Autopay" />
                <h4 class="light-green lighten-4 font-weight-light" v-if="savedCustomerData.autopay"> AutoPay is enabled
                </h4>
                <h4 class="red lighten-3 font-weight-light" v-else>AutoPay is disabled </h4>
              </v-card>
            </v-col>
          </v-row>
          <v-row no-gutters v-if="braintreeAccount">
            <v-col offset-md="1">
              <Braintree class="braintree-button" :customer-id="customerId" :customer="customer"
                :braintree-customer="braintreeCustomer" @bank-added="updateList" @error="triggerAlert" />
            </v-col>
            <v-col>
              <v-dialog v-model="cardDialog" persistent width="750">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on" class="braintree-button">
                    Add Card
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    <v-row align="center">
                      <v-col cols="10">
                        Add Card to this account
                      </v-col>
                      <v-col cols="2" class="text-right">
                        <v-btn icon color="pink">
                          <v-icon @click="closeDialog">mdi-close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-stepper alt-labels v-model="activeStep" color="success">
                    <v-stepper-header>
                      <v-stepper-step :complete="activeStep > 1" step="1">
                        Add CC Payment
                      </v-stepper-step>
                      <v-divider />
                      <v-stepper-step :complete="activeStep > 2" step="2">
                        CC Authorization Form
                      </v-stepper-step>
                      <v-divider />
                      <v-stepper-step step="3" :complete="activeStep > 3">
                        CC Anvil
                      </v-stepper-step>
                      <v-divider />
                      <v-stepper-step step="4">
                        Complete
                      </v-stepper-step>
                      <v-divider />
                    </v-stepper-header>
                    <v-stepper-items>
                      <v-stepper-content step="1">
                        <v-card-text>
                          <CreditCardForm :customer-id="customerId" @added-card="setCardDetails" @errored="handleError" />
                        </v-card-text>
                      </v-stepper-content>
                      <v-stepper-content step="2">
                        <GenerateCCForm :customer-id="customerId" :customer="customer" :card="creditCardDetails"
                          @signer-agreement="getSignerUrl" />
                      </v-stepper-content>
                      <v-stepper-content step="3">
                        <CreditCardAnvil v-show="signerUrl" :url="signerUrl" @completed="saveAgreement"
                          @error="anvilError" />
                      </v-stepper-content>
                      <v-stepper-content step="4">
                        <v-overlay :opacity="0.5" :value="loading">
                          <v-progress-circular indeterminate size="64" />
                        </v-overlay>
                      </v-stepper-content>
                    </v-stepper-items>
                  </v-stepper>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
          <!-- Add Card -->
          <v-container>
            <v-subheader v-if="braintreeCustomer.paymentMethods.length > 0">
              Payment methods:
            </v-subheader>
            <v-row no-gutters>
              <v-col>
                <v-list three-line>
                  <template v-if="loading">
                    <div class="loader">
                      <v-progress-circular indeterminate color="primary" />
                    </div>
                  </template>
                  <template v-else v-for="(item, index) in braintreeCustomer.usBankAccounts">
                    <v-subheader v-if="item.bankName" :key="item.createdAt" v-text="item.bankName" />
                    <v-list-item :key="`${item.imageUrl}${index}`">
                      <v-list-item-avatar>
                        <v-img :src="item.imageUrl" />
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>
                          Account Number: {{ item.last4 }}
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="item.default">
                          <v-chip text-color="white" color="green">
                            Default
                          </v-chip>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          Account Type: {{ item.accountType }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                          v-if="item.verifications && item.verifications[0] && item.verifications[0].verificationMethod">
                          Verification Method: {{ item.verifications[0].verificationMethod }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <v-row v-if="item.verified">
                            <v-col cols="12" md="4">
                              Verified:
                              <v-icon color="green darken-2">
                                mdi-checkbox-marked
                              </v-icon>
                            </v-col>
                          </v-row>
                          <v-row v-else>
                            <v-dialog v-model="dialog" width="750">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn class="ma-2" color="primary" dark v-bind="attrs" v-on="on"
                                  v-if="item.verified === false && (!item.verifications || item.verifications[0].status === 'pending') && !item.verificationSubmitted"
                                  @click="selectedPayment = item">
                                  Verification in process
                                  <v-icon dark right color="red">
                                    mdi-alert
                                  </v-icon>
                                </v-btn>
                                <v-btn disabled large
                                  v-else-if="item.verifications[0].status === 'pending' && item.verificationSubmitted">
                                  Verification completed, waiting for settlement.
                                </v-btn>
                                <v-btn disabled large color="error"
                                  v-else-if="item.verifications[0].status === 'failed' || item.verifications[0].status === 'processor_declined'">
                                  Verification failed.
                                </v-btn>
                              </template>
                              <v-card>
                                <v-alert v-if="showVerifyMessage" dense outlined prominent shaped text type="error">
                                  {{ error }}
                                </v-alert>
                                <v-card-title class="text-h5 grey lighten-2">
                                  Micro Transfer.
                                </v-card-title>

                                <v-card-text>
                                  Two micro transfers have been intiated with your
                                  account on behalf of Paypal.
                                </v-card-text>

                                <v-divider />
                                <v-container>
                                  <v-row>
                                    <v-col cols="12" md="4">
                                      <v-label> 1st transaction </v-label>
                                      <v-row>
                                        <v-col cols="10" sm="4">
                                          <v-subheader class="label">
                                            0.
                                          </v-subheader>
                                        </v-col>
                                        <v-col>
                                          <v-otp-input label="1st Transaction" length="2" type="number"
                                            v-model="verify.first" :rules="requiredRules" />
                                        </v-col>
                                      </v-row>
                                    </v-col>
                                    <v-divider />
                                    <v-col cols="12" md="4">
                                      <v-label> 2nd transaction </v-label>
                                      <v-row>
                                        <v-col cols="12" sm="4">
                                          <v-subheader class="label">
                                            0.
                                          </v-subheader>
                                        </v-col>
                                        <v-col>
                                          <v-otp-input label="2nd Transaction" length="2" type="number"
                                            v-model="verify.second" :rules="requiredRules" />
                                        </v-col>
                                      </v-row>
                                    </v-col>
                                  </v-row>
                                </v-container>
                                <v-card-actions>
                                  <v-spacer />
                                  <v-btn :disabled="!verify.second || !verify.first" color="primary" text
                                    @click="sendVerify" :loading="verifyLoading">
                                    Verify
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-row>
                        </v-list-item-subtitle>
                        <v-container>
                          <v-row>
                            <v-col cols="12" md="4">
                              <v-list-item-action-text>
                                Delete
                              </v-list-item-action-text>
                              <v-btn icon @click="() => deletePayment(item)">
                                <v-icon color="red lighten-1">
                                  mdi-delete-forever
                                </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-container>
                        <v-container>
                          <v-row v-if="!item.default">
                            <v-col cols="12" md="4">
                              <v-btn color="green lighten-2" elevation="3" outlined :disabled="!item.verified"
                                @click="() => updatePayment(item)">
                                Make Default
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-container>
                        <!-- </v-list-item-action> -->
                        <!-- <v-list-item-action
                          v-if="!item.default"
                          class="action"
                        > -->
                        <!-- @click="() => updatePayment(item)" -->
                        <!-- </v-list-item-action> -->
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </v-col>
              <v-col cols="12" sm="4">
                <v-list three-line>
                  <template v-if="loading">
                    <div class="loader">
                      <v-progress-circular indeterminate color="primary" />
                    </div>
                  </template>
                  <template v-else v-for="(item, index) in braintreeCustomer.creditCards">
                    <v-subheader :key="item.createdAt" v-text="item.cardType" />
                    <v-list-item :key="`${item.imageUrl}${index}`">
                      <v-list-item-avatar>
                        <v-img :src="item.imageUrl" />
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>
                          Credit Card: {{ item.maskedNumber }}
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="item.default">
                          <v-chip text-color="white" color="green">
                            Default
                          </v-chip>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <v-row v-if="item.verification &&
                            item.verification.status === 'verified'
                            ">
                            <v-col cols="12" md="4">
                              Verified:
                              <v-icon color="green darken-2">
                                mdi-checkbox-marked
                              </v-icon>
                            </v-col>
                          </v-row>
                        </v-list-item-subtitle>
                        <v-container>
                          <v-row>
                            <v-col cols="12" md="4">
                              <v-list-item-action-text>
                                Delete
                              </v-list-item-action-text>
                              <v-btn icon @click="() => deletePayment(item)">
                                <v-icon color="red lighten-1">
                                  mdi-delete-forever
                                </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-container>
                        <v-container>
                          <v-row v-if="!item.default">
                            <v-col cols="12" md="4">
                              <v-btn color="green lighten-2" elevation="3" outlined :disabled="!(item.verification &&
                                item.verification.status === 'verified')" @click="() => updatePayment(item)">
                                Make Default
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <!-- <v-tab-item v-if="card">
          <CreditCardForm :customer-id="customerId" @added-card="updateList" />
        </v-tab-item> -->
        <v-tab-item>
          <PaymentReceipts :customer-id="customerId" />
        </v-tab-item>
        <v-tab-item>
          <Employees :customer-id="customerId" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-card v-else>
      <v-overlay :opacity="0.5" :value="initialLoading">
        <v-progress-circular indeterminate size="64" />
      </v-overlay>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import * as Sentry from "@sentry/vue";
import { CUSTOMER_API_BASE, PG_API_BASE, AUTH_API_BASE, ANVIL_TEMPLATE_ID, UPLOAD_API } from "../env";
import Braintree from "@/components/Braintree.vue";
import State from "@/components/State";
import CreditCardForm from "@/components/CreditCardForm.vue";
import Employees from "@/components/Employees.vue";
import Lease from "@/components/Lease.vue";
import GenerateCCForm from "@/components/GenerateCCForm.vue";
import CreditCardAnvil from '@/components//CreditCardAnvil.vue';
import PaymentReceipts from '@/components/PaymentReceipts';
import RsAnvilApi from '../api/rsAnvilApi';

export default {
  components: { Braintree, State, CreditCardForm, Employees, GenerateCCForm, CreditCardAnvil, Lease, PaymentReceipts },
  computed: {
    zipRules() {
      return !this.customer.zip
        ? []
        : this.customer.country === "us"
          ? this.usZipRules
          : this.caZipRules;
    },
  },
  async mounted() {
    try {
      this.initialLoading = true;
      this.loading = true;
      this.card = true;
      const { given_name, family_name, email, customer } =
        this.$keycloak.tokenParsed;
      const customerId = customer[0];

      this.customerId = customerId;
      if (given_name) {
        this.customer.firstName = given_name;
      }

      if (family_name) {
        this.customer.lastName = family_name;
      }

      this.customer.email = email;

      if (customerId) {
        const customer = await axios.get(
          `${CUSTOMER_API_BASE}/customer/${customerId}`,
          {
            headers: {
              Authorization: `Bearer ${this.$keycloak.token}`,
            },
          }
        );
        this.savedCustomerData = customer.data;

        this.customer = {
          company: customer.data.company,
          email: customer.data.email,
          userName: customer.data.userName,
          address: customer.data.address,
          address2: customer.data.address2,
          city: customer.data.city,
          state: customer.data.state,
          zip: customer.data.zip,
          phone: customer.data.phone,
          crmEnabled: customer.data.crmEnabled,
          business: customer.data.business,
          firstName: given_name,
          lastName: family_name,
          country: customer.data.country,
        };

        this.customerData = { ...this.customer };

        this.tax = customer.data.taxId;
        this.company = customer.data.company;

        try {
          const braintreeAccount = await this.getCustomerFromBraintree();
          if (!braintreeAccount) {
            this.braintreeAccount = false;
          } else {
            this.braintreeAccount = true;
            this.braintreeCustomer = braintreeAccount;
          }
        } catch (error) {
          this.braintreeAccount = false;
        }
      } else {
        this.braintreeAccount = false;
      }

      this.loading = false;
      this.initialLoading = false;
    } catch (err) {
      this.initialLoading = false;
      this.loading = false;
      this.error = err.response.data;
      throw new Error(err.response.data);
    }
  },
  data() {
    return {
      activeStep: 1,
      password: '',
      passwordVisible: false,
      creditCardDetails: {},
      signerUrl: '',
      customerData: {},
      savedCustomerData: {},
      notes: {
        description: [],
      },
      cardDialog: false,
      show: false,
      snackbar: false,
      snackbarText: "",
      valid: false,
      achVerifications: [],
      tab: this.$route.query.payment ? 2 : 0,
      error: "",
      showError: false,
      cardAdded: false,
      dialog: false,
      verifyLoading: false,
      showVerifyMessage: false,
      msg: "",
      tax: '',
      company: '',
      etchPacket: '',
      customerId: null,
      braintreeCustomer: null,
      braintreeAccount: false,
      card: false,
      loading: true,
      initialLoading: true,
      verify: {
        first: null,
        second: null,
      },
      countries: [
        {
          value: "us",
          name: "United States of America",
        },
        {
          value: "ca",
          name: "Canada",
        },
      ],
      selectedPayment: null,
      customer: {
        company: "",
        firstName: "",
        lastName: "",
        email: "",
        userName: "",
        taxId: "",
        address: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        phone: "",
        crmEnabled: true,
        business: "",
        country: 'us',
      },
      nameRules: [(v) => !!v || "Name is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      caZipRules: [
        (v) =>
          /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(v) ||
          "Zipcode must be valid",
      ],
      usZipRules: [
        (v) => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(v) || "Zipcode must be valid",
      ],
      requiredRules: [(v) => !!v || "Field is required"],
    };
  },
  methods: {
    changeTab(index) {
      this.tab = index;
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    async resetPassword() {
      try {
        this.loading = true;
        const response = await axios.put(`${AUTH_API_BASE}/customer/${this.$keycloak.tokenParsed.sub}/password/reset-password`, {
          value: this.password,
          type: 'password',
        },
          {
            headers: {
              Authorization: `Bearer ${this.$keycloak.token}`,
            },
          },
        );
        this.loading = false;
        this.snackbar = true;
        this.snackbarText = response.data;
      } catch (err) {
        this.loading = false;
        this.triggerAlert(err.response.data);
      }
    },
    async setCardDetails(card) {
      this.creditCardDetails = card;
      await axios.put(
        `${CUSTOMER_API_BASE}/customer/${this.customerId}`,
        {
          notes: {
            type: 'Profile Changes',
            description: JSON.stringify({ creditCardAdded: card.creditCard }),
            createdBy: this.$keycloak.tokenParsed.email,
            createdAt: new Date(),
          }
        },
        {
          headers: {
            Authorization: `Bearer ${this.$keycloak.token}`,
          },
        },
      );
      this.activeStep = 2;
    },
    getSignerUrl(url, etch) {
      this.signerUrl = url;
      this.etchPacket = etch;
      this.activeStep = 3;
    },
    async handleError(mes) {
      this.snackbar = true;
      this.snackbarText = "Check your card details and try again.";
      await axios.put(
        `${CUSTOMER_API_BASE}/customer/${this.customerId}`,
        {
          notes: {
            type: 'Profile Changes',
            description: JSON.stringify({ creditCardAddedError: mes }),
            createdBy: this.$keycloak.tokenParsed.email,
            createdAt: new Date(),
          }
        },
        {
          headers: {
            Authorization: `Bearer ${this.$keycloak.token}`,
          },
        },
      );
    },
    anvilError(err) {
      this.closeDialog();
      Sentry.captureException(err);
    },
    getCreditCardsAddedByUser() {
      const creditCardEvents = this.savedCustomerData.notes.filter(item => {
        if (item.type === 'Profile Change' || item.type === 'Profile Changes') {
          try {
            const parsedData = JSON.parse(item.description);
            if (parsedData.creditCardAdded) {
              return item;
            }
          } catch (err) {
            console.log(err);
          }
        }
      });

      return creditCardEvents.map(item => ({ ...JSON.parse(item.description) }));
    },
    async deleteCardIfNotSigned() {
      const creditCardEvents = this.getCreditCardsAddedByUser();
      const creditCards = creditCardEvents.map(card => card.creditCardAdded.token);
      const btCreditCards = this.braintreeCustomer.creditCards.map(card => card.token);
      const customerAddedCreditCardsWichAreInBT = creditCards.filter(addedCard => btCreditCards.includes(addedCard));

      if (customerAddedCreditCardsWichAreInBT.length) {
        for (const card of customerAddedCreditCardsWichAreInBT) {
          const [doc] = this.savedCustomerData.docs.filter(doc => doc.referenceId === card);

          if ((doc && !doc.url) || !doc) {
            try {
              await axios.delete(`${PG_API_BASE}/customer/payment/${card}`, {
                headers: {
                  Authorization: `Bearer ${this.$keycloak.token}`,
                },
              });
            } catch (err) {
              console.log(err);
            }
          }
        }
      }
    },
    async closeDialog() {
      try {
        this.loading = true;
        this.cardDialog = false;
        this.braintreeCustomer.creditCards = [];
        const customer = await axios.get(
          `${CUSTOMER_API_BASE}/customer/${this.customerId}`,
          {
            headers: {
              Authorization: `Bearer ${this.$keycloak.token}`,
            },
          }
        );
        this.savedCustomerData = customer.data;
        await this.deleteCardIfNotSigned();
        await this.updateList();
        this.loading = false;
        this.activeStep = 1;
      } catch (err) {
        this.loading = false;
        this.triggerAlert(err.message);
        this.cardDialog = false;
      }
    },
    async saveAgreement() {
      try {
        this.activeStep = 4;
        this.loading = true;
        const anvilClient = new RsAnvilApi(this.$keycloak.token);
        const documentEid = this.etchPacket.eid;
        const documentGroupEid = this.etchPacket.documentGroup.eid;

        let signStatusData;
        const signerResponse = await anvilClient.getSignersStatus(this.etchPacket.eid, this.customerId);
        if (signerResponse.data && signerResponse.data.data && signerResponse.data.data.etchPacket) {
          signStatusData = signerResponse.data.data.etchPacket
        }

        if (signStatusData.status === 'completed') {
          const agreementBuffer = await anvilClient.downloadAgreement(documentGroupEid, this.customerId);
          const formData = new FormData();
          const buffer = new Uint8Array(agreementBuffer.data.data);
          const blob = new Blob([buffer], { type: 'application/pdf' });
          formData.append('document', blob, `${documentEid}.pdf`);

          const response = await axios.post(UPLOAD_API, formData, {
            headers: {
              Authorization: `Bearer ${this.$keycloak.token}`,
              'Content-Type': 'multipart/form-data',
            },
          });

          const customerResponse = await axios.put(
            `${CUSTOMER_API_BASE}/customer/${this.customerId}`,
            {
              docs: {
                url: response.data.url,
                createdAt: new Date(),
                documentEid,
                documentGroupEid,
                documentUrl: this.etchPacket.detailsURL,
                type: 'CREDIT_CARD_AUTHORIZATION',
                templateId: ANVIL_TEMPLATE_ID,
                referenceId: this.creditCardDetails.creditCard.token,
              },
              notes: {
                type: 'Profile Changes',
                description: JSON.stringify({ creditCardSigned: response.data.url }),
                createdBy: this.$keycloak.tokenParsed.email,
                createdAt: new Date(),
              }
            },
            {
              headers: {
                Authorization: `Bearer ${this.$keycloak.token}`,
              },
            },
          );

          this.savedCustomerData = customerResponse.data;
          this.cardAdded = true;
          this.snackbar = true;
          this.snackbarText = "Successfully added card";
        } else {
          this.snackbar = true;
          this.snackbarText = "Document needs to be signed";
          throw new Error(JSON.stringify(signerResponse.data));
        }
        this.cardDialog = false;
        this.activeStep = 1;
        this.loading = false;
        await this.updateList();
      } catch (err) {
        this.loading = false;
        this.cardAdded = false;
        console.log(err);
        this.snackbar = true;
        this.snackbarText =
          err.message + " Something went wrong.";
        this.activeStep = 1;
      }
    },
    resetZip() {
      this.customer.zip = "";
    },
    async sendVerify() {
      this.verifyLoading = true;
      if (
        !this.selectedPayment.verifications ||
        this.selectedPayment.verifications.length === 0
      ) {
        this.verifyLoading = false;
        this.showVerifyMessage = true;
        return;
      }
      try {
        const response = await axios.put(
          `${PG_API_BASE}/customer/payment/bank/verify/micro/transfers/${this.selectedPayment.verifications[0].id}`,
          {
            first: this.verify.first,
            second: this.verify.second,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$keycloak.token}`,
            },
          }
        );

        await axios.post(`${CUSTOMER_API_BASE}/ach/verfications/${this.customerId}`, {
          id: this.selectedPayment.verifications[0].id,
          customerId: this.customerId,
          submitted: true,
          token: this.selectedPayment.token,
          details: {
            first: this.verify.first,
            second: this.verify.second,
          },
        }, {
          headers: {
            Authorization: `Bearer ${this.$keycloak.token}`,
          }
        });

        if (!response.data.success) {
          this.showVerifyMessage = true;
          this.verifyLoading = false;
          this.error = response.data.message;
          return;
        }

        this.snackbarText = 'The payment method may take 3 days to complete the settlement and mark as verified.';
        this.snackbar = true;
        this.verifyLoading = false;

        this.msg = response.data.msg;
        this.verifyLoading = false;
        this.showVerifyMessage = false;
        this.dialog = false;
        await this.updateList();
        this.verify.first = "";
        this.verify.second = "";
      } catch (err) {
        console.log(err);
        this.verifyLoading = false;
        this.showVerifyMessage = true;
        this.error = err.message;
      }
    },
    async getSubmittedVerifications() {
      const response = await axios.get(`${CUSTOMER_API_BASE}/ach/verfications/${this.customerId}`, {
        headers: {
          Authorization: `Bearer ${this.$keycloak.token}`,
        }
      });
      this.achVerifications = response.data;

      if (this.braintreeCustomer && this.braintreeCustomer.usBankAccounts && this.braintreeCustomer.usBankAccounts.length) {
        this.braintreeCustomer.usBankAccounts.forEach(method => {
          const { token } = method;

          const [foundLocalVerification] = this.achVerifications.filter(item => item.token === token);
          if (foundLocalVerification && foundLocalVerification.submitted) {
            method.verificationSubmitted = true;
          }
        });
      }
    },
    async updateAutopay(value) {
      this.loading = true;
      await axios.put(
        `${CUSTOMER_API_BASE}/customer/${this.customerId}`,
        {
          autopay: value
        },
        {
          headers: {
            Authorization: `Bearer ${this.$keycloak.token}`,
          },
        }
      );
      this.loading = false;
    },
    async submit() {
      this.loading = true;
      const valid = this.$refs.form.validate();
      if (!valid) {
        this.loading = false;
        return;
      }
      let jsonData = {};

      const customerDataArr = Object.values(this.customerData);
      const customerArr = Object.values(this.customer);
      const customerFields = Object.keys(this.customer);

      customerDataArr.forEach(async (item, index) => {
        if (item !== customerArr[index]) {
          jsonData[customerFields[index]] = `${customerDataArr[index]} changed to ${customerArr[index]}`;
        }
      });

      if (Object.keys(jsonData).length > 0) {
        try {
          if (!this.customerId) {
            this.snackbarText =
              "Customer is not linked, please ask your admin to link the account";
            this.snackbar = true;
            this.loading = false;
            return;
          }

          await axios.put(
            `${CUSTOMER_API_BASE}/customer/${this.customerId}`,
            {
              ...this.customer,
              notes: {
                type: 'Profile Changes',
                description: JSON.stringify(jsonData),
                createdBy: this.$keycloak.tokenParsed.email,
                createdAt: new Date(),
              },
              country: this.customer.country,
              userName: this.customer.email,
            },
            {
              headers: {
                Authorization: `Bearer ${this.$keycloak.token}`,
              },
            }
          );

          await axios.put(
            `${PG_API_BASE}/customer/${this.customerId}`,
            {
              firstName: this.customer.firstName,
              lastName: this.customer.lastName,
              phone: this.customer.phone,
              // creditCard: {
              //   billingAddress: {
              //     region: this.customer.state,
              //     postalCode: this.customer.zip,
              //     locality: this.customer.city,
              //     countryCodeAlpha2: String(this.country).toUpperCase(),
              //     options: { updateExisting: true }
              //   },
              // }
            },
            {
              headers: {
                Authorization: `Bearer ${this.$keycloak.token}`,
              },
            }
          );

          const customer = await axios.get(
            `${CUSTOMER_API_BASE}/customer/${this.customerId}`,
            {
              headers: {
                Authorization: `Bearer ${this.$keycloak.token}`,
              },
            }
          );
          this.savedCustomerData = customer.data;

          this.customerData = { ...this.customer };

          this.snackbarText = "Customer details updated!";
          this.snackbar = true;
          this.loading = false;
        } catch (err) {
          this.error = err.response.data;
          throw new Error(err.response.data);
        }
      }
      this.loading = false;
    },
    async submitPrimaryContact() {
      this.loading = true;
      const valid = this.$refs.form.validate();
      if (!valid) {
        this.loading = false;
        return;
      }
      let jsonData = {};

      const fullName = this.savedCustomerData.primaryContact.name;
      const name = fullName.split(' ');
      let firstName = name[0], lastName = name[1];

      let fName, lName;
      if (name[0] !== this.customer.firstName) {
        jsonData['firstName'] = `${firstName} changed to ${this.customer.firstName}`;
        fName = true;
      }

      if (name[1] !== this.customer.lastName) {
        jsonData['lastName'] = `${lastName} changed to ${this.customer.lastName}`;
        lName = true;
      }

      if (Object.keys(jsonData).length > 0) {
        try {
          if (!this.customerId) {
            this.snackbarText =
              "Customer is not linked, please ask your admin to link the account";
            this.snackbar = true;
            this.loading = false;
            return;
          }

          await axios.put(
            `${CUSTOMER_API_BASE}/customer/${this.customerId}`,
            {
              notes: {
                type: 'Profile Changes',
                description: JSON.stringify(jsonData),
                createdBy: this.$keycloak.tokenParsed.email,
                createdAt: new Date(),
              },
              fullName: `${this.customer.firstName} ${this.customer.lastName}`,
              primaryContact: {
                access: "PRIMARY",
                name: `${this.customer.firstName} ${this.customer.lastName}`,
              },
            },
            {
              headers: {
                Authorization: `Bearer ${this.$keycloak.token}`,
              },
            }
          );

          await axios.put(
            `${PG_API_BASE}/customer/${this.customerId}`,
            {
              firstName: this.customer.firstName,
              lastName: this.customer.lastName,
              phone: this.customer.phone,
              // creditCard: {
              //   billingAddress: {
              //     region: this.customer.state,
              //     postalCode: this.customer.zip,
              //     locality: this.customer.city,
              //     countryCodeAlpha2: String(this.country).toUpperCase(),
              //     options: { updateExisting: true }
              //   },
              // }
            },
            {
              headers: {
                Authorization: `Bearer ${this.$keycloak.token}`,
              },
            }
          );

          const customer = await axios.get(
            `${CUSTOMER_API_BASE}/customer/${this.customerId}`,
            {
              headers: {
                Authorization: `Bearer ${this.$keycloak.token}`,
              },
            }
          );
          this.savedCustomerData = customer.data;

          this.customerData = { ...this.customer };

          if (fName && lName) {
            firstName = this.customer.firstName;
            lastName = this.customer.lastName
          } else if (fName) {
            firstName = this.customer.firstName;
          } else if (lName) {
            lastName = this.customer.lastName
          }

          await this.updateCRMKeycloakAccount(firstName, lastName);

          this.snackbarText = "Customer details updated!";
          this.snackbar = true;
          this.loading = false;
        } catch (err) {
          this.error = err.response.data;
          throw new Error(err.response.data);
        }
      }
      this.loading = false;
    },
    async getDetailsFromCustomersAPI() { },
      async getCustomerFromBraintree() {
        try {
          this.loading= true;
          const response = await axios.get(
           `${PG_API_BASE}/customer/${this.customerId}`,
           {
             headers: {
               Authorization: `Bearer ${this.$keycloak.token}`,
              },
            }
          );
          this.braintreeCustomer = response.data.customer;
          this.loading = false;
          return response.data.customer;  
        } catch (err) {
           this.loading = false;
           return null;
        }
       },
    async createBraintreeAccount() {
      try {
        await axios.post(
          `${PG_API_BASE}/customer/create`,
          {
            id: this.customerId,
            firstName: this.customer.firstName,
            lastName: this.customer.lastName,
            email: this.customer.email,
            phone: this.customer.phone,
            company: this.customer.business,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$keycloak.token}`,
            },
          }
        );
        await axios.put(`${CUSTOMER_API_BASE}/${this.customerId}`, {
          customerId: true,
        });
      } catch (err) {
        throw new Error(err.response.data);
      }
    },
    async updateCRMKeycloakAccount(firstName, lastName) {
      this.loading = true;
      await axios.put(`${AUTH_API_BASE}/customer/${this.$keycloak.tokenParsed.sub}`, {
        firstName,
        lastName,
      }, {
        headers: {
          Authorization: `Bearer ${this.$keycloak.token}`,
        },
      });
      this.loading = false;
      this.$keycloak.refreshTokenParsed()
    },
    async deletePayment(item) {
      try {
        this.loading = true;
        await axios.delete(`${PG_API_BASE}/customer/payment/${item.token}`, {
          headers: {
            Authorization: `Bearer ${this.$keycloak.token}`,
          },
        });
        await this.updateList();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.triggerAlert(err.message);
      }
    },
    async updatePayment(item) {
      try {
        this.loading = true;
        await axios.put(
          `${PG_API_BASE}/customer/${item.customerId}`,
          {
            defaultPaymentMethodToken: item.token,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$keycloak.token}`,
            },
          }
        );
        await this.updateList();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.triggerAlert(err.message);
      }
    },
    async updateList() {
      this.loading = true;
      const braintreeAccount = await this.getCustomerFromBraintree();
      this.braintreeCustomer = braintreeAccount;
      await this.getSubmittedVerifications();
      this.loading = false;
    },
    triggerAlert(message) {
      this.error = message;
      this.showError = true;
    },
  },
};
</script>

<style scoped>
.braintree-button {
  height: 130px;
}

.action {
  cursor: pointer;
}

.label {
  font-size: 20px;
}

.loader {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 10%;
}
</style>
